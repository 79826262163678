<template>
  <div class="empty">
    <!-- placeholder -->
  </div>
</template>

<script>

import {
  defineComponent,
} from '@nuxtjs/composition-api';
import { useUser } from '@vue-storefront/gemini';
import { useI18n } from '../../helpers/hooks/usei18n';

export default defineComponent({
  name: 'PageTrackingGtm',
  props: {
    pageTitleGtm: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    pageTypeGtm: {
      type: String,
      required: false,
      default() {
        return 'other';
      },
    },
  },
  setup() {
    const { locale } = useI18n();
    const {
      isAuthenticated, user, loading, load,
    } = useUser();

    return {
      locale, isAuthenticated, user, loading, load,
    };
  },
  async mounted() {
    if (!this.user) {
      await this.load();
    }
    this.$nextTick(() => {
      this.$gtm.push({
        event: 'data_loaded',
        pageLang: this.locale,
        pageType: this.pageTypeGtm,
        visitorId: this.isAuthenticated ? this.user.uid : 'undefined',
      });
      // eslint-disable-next-line unicorn/no-array-push-push
      this.$gtm.push({
        event: 'PageView',
        content_name: this.pageTitleGtm,
        content_url: `${window.location.origin}${this.$route.fullPath}`,
      });
    });
  },

});
</script>

<style scoped>

</style>
